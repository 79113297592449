@import "../ReactiveMixins";

.App-header {
  font-size: 2.5em;
  color: #000000;


  @include small() {
    font-size: 2em;
    margin-top: 50px;
  }
}

.title {

  h1 {
    text-transform: none;
    font-weight: 700;
    font-size: 0.9em;
    margin-block-end: 0;
    margin-top: 5px;

    @include small() {
      margin-top: 0;
    }
  }

  p {
    text-transform: uppercase;
    font-size: 49%;
    position: relative;
    top: -3px;
    margin-block-start: 0;
  }

}

.loginOrLogoutContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: right;
}

.login,
.logout,
.signup,
.add-spot {
  margin-left: 0;
  flex: 1;
}