@import "../../ReactiveMixins";
@import "./spot/Spot";

.draggable-container {
  padding: 10px;
}

.spotsContainer {
  flex: 1;
  width: 700px;
  margin: 0 auto auto auto;

  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }

  @include small {
    width: 100%;
    padding: 5px;
  }
}

.hide {
  visibility: hidden;
}

.collapsibleIcon {
  margin: 0 10px;
  flex: 0.03;
}

.collapsibleIcon>img {
  width: 100%;
  cursor: pointer;
}

.item.dragging {
  opacity: 0.6;
}