@import "../../../../ReactiveMixins";
@import "../../../../index";

.measurements {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 7px;

  @include small {
    flex-direction: row;
  }
}

@include small {

  @media (pointer: coarse),
  (hover: none) {
    [title] {
      position: relative;
      display: inline-flex;
      justify-content: center;
    }

    [title]:focus::after {
      content: attr(title);
      position: absolute;
      top: 90%;
      color: #000;
      width: fit-content;
      padding: 3px;
      font-weight: normal;
      font-size: 16px;
    }
  }
}

.timestamp {
  color: gray;

  @include small {
    display: none;
  }
}

.measurement_row {
  flex: 1;
}

.meas {
  padding: 0 20px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-content: center;
  align-items: baseline;
  column-gap: 2px;
}

.flow {
  font-size: 120%;
}

.temp {
  font-size: 90%;
}

.unit {
  font-size: 70%;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* colors for flow */
.flow_bad {
  color: #e2576f;
}

.flow_good {
  color: #409073;
}

// for future use
.flow_ok {
  color: #f8cd4c;
}

.flow_danger {
  color: #e2576f;
}