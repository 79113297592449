$msw-blue: #3bbae8;
$msw-blue-secondary: #2473db;

body {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}

a {
  color: $msw-blue;
}

.btn-msw {
  background-color: $msw-blue;
  color: white;

  &:hover {
    background-color: white;
    color: $msw-blue;
    border-color: $msw-blue;
  }
}

.btn-msw-outline {
  background-color: white;
  color: $msw-blue;
  border-color: $msw-blue;

  &:hover {
    background-color: $msw-blue;
    color: white;
  }
}

.btn-danger {
  &:hover {
    background-color: white;
    color: #dc3545;
    border-color: #dc3545;
  }
}

.btn-link {
  color: $msw-blue;
}