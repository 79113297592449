@import "../ReactiveMixins";
@import "../index";

.Footer {
  margin-top: 30px;
  padding: 1.25rem;
  color: white;
  background-color: $msw-blue;

  .Footer_list {
    list-style: none;
    padding: 0;
    margin: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include small {
      flex-direction: column;
    }

    @include medium {
      flex-direction: column;
    }

    li {
      margin: 1rem;
      min-width: 14rem;
    }

    a {
      color: white;
    }
  }
}