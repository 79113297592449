.success-msg {
  color: green;
  margin-top: 24px;
}

.link-to-login {
  margin-top: 20px;
  display: block;
  text-align: center;
  color: black;
}