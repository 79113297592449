.loader {
  align-self: center;
  width: 70px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

.wave {
  position: relative;
  width: 100%;
  height: 100%;
  background: #2C74B3;
  border-radius: 50%;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0);
}

.wave:before, .wave:after {
  content: '';
  position: absolute;
  left: 50%;
  width: 200%;
  height: 200%;
}

.wave:before {
  border-radius: 45%;
  background: rgba(255, 255, 255, 1);
  animation: animate 5s linear infinite;
}

.wave:after {
  border-radius: 40%;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}