.graph {
  font-weight: normal;
  font-size: 15px;
}

.tooltip {
  background-color: white;
  border-radius: 3px;
  padding: 3px;
  opacity: 0.8;
  text-transform: uppercase;
}

.tooltip_timestamp {
  font-weight: 600;
  margin: 5px;
}

.tooltip_value {
  margin: 5px;
}