@import "../index";

.container {
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status-code {
  color: $msw-blue;
  font-weight: bold;
}

.status-title {
  font-weight: 900;
  font-size: min(8vw, 35px);
}

.status-explanation {
  font-size: min(4vw, 16px);
}

.row {
  margin: 10px 0;
}