.miniGraph {
  flex: 1;
  width: 100%;
  max-width: 350px;
  margin: auto;
}

.forecastContainer {
  font-size: 20px;
  flex: 2;
  align-items: center;
  display: flex;
  column-gap: 5px;
  width: 100%;
}