@import "../ReactiveMixins";
@import "../index";

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.surfspots {
  column-gap: 40px;
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: row wrap;
  color: $msw-blue;

  @include medium {
    flex-direction: column;
  }

  @include small {
    flex-direction: column;
    width: 100%;
  }
}
