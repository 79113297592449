@import "../../../ReactiveMixins";
@import "../../../index";

.details-title {
  color: $msw-blue;
}

$icons-container-width: 40px;

.spot {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #000000;
  background-color: #ececec;
  border-radius: 20px;
}

.spot-overview {
  display: flex;
}

.spot-desktop {
  @include small {
    display: none;
  }
}

.spot-mobile {
  .forecastContainer {
    display: none;
  }

  @include small () {
    display: flex;
  }

  display: none;
  flex-direction: column;
}

.icons-container {
  display: flex;
  align-content: center;
  flex-direction: column;
  width: $icons-container-width;
}

.icon {
  flex: 1;
  align-content: center;

  margin: 5px 0;

  img {
    width: 60%;
  }
}

details>summary div.collapsible-icon {
  transition: all 1s;
  align-self: center;
  width: 100%;
}

details[open] summary div.collapsible-icon {
  transform: rotate(180deg);
}

.collapsible-icon {
  img {
    cursor: pointer;
  }

  @include small {
    display: none
  }
}

summary::-webkit-details-marker {
  display: none;
}

.collapsibleContent {
  display: flex;
  margin: 20px 10px;
}

.spotname {
  display: flex;
  width: 100%;
}

.spot-title>a {
  flex: 1;
  text-overflow: ellipsis;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 85%;
}

.spot-title {
  flex: 1;
  word-break: break-word;
}

.spotname a:hover {
  text-decoration: underline;
}

details.spot[open] summary.spotname {
  .forecastContainer {
    display: none;
  }

  .measurements {
    flex-direction: row;
  }
}

.spotContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 130%;
  font-weight: bold;
  margin: 20px 0;

  @include small() {
    flex-direction: column;
    row-gap: 20px;
  }
}

.arrow-icon {
  img {
    width: 40%;
  }
}

.last40days-container {
  width: 100%;
}